// import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import './App.css';

import HomePage        from "./pages/home-page/HomePage"
import FormCreate      from "./pages/form-create/FormCreate"
import FormEdit        from "./pages/form-edit/FormEdit"
import FormView        from "./pages/form-view/FormView"
import Error404page    from './pages/message-page/Error404page'
import MessagePage     from './pages/message-page/MessagePage'
import ResponseDetails from './pages/response-page/ResponseDetails'
import ResponsePage    from './pages/response-page/ResponsePage'
import Navbar from './pages/layout/Navbar';
import ChartPage from './pages/chart/ChartPage';
import ReportCreatePage from './pages/report-create/ReportCreatePage';
import ReportEditPage from './pages/report-edit/ReportEditPage';
import LoginPage from './pages/login/LoginPage';
import ProtectedRoutes from './routes/ProtectedRoutes';
import Cookies from 'universal-cookie';
import NotificationContainer from './utility/NotificationContainer';

// const HomePage        = lazy(() => import("./pages/home-page/HomePage"))
// const FormCreate      = lazy(() => import("./pages/form-create/FormCreate"))
// const FormEdit        = lazy(() => import("./pages/form-edit/FormEdit"))
// const FormView        = lazy(() => import("./pages/form-view/FormView"))
// const Error404page    = lazy(() => import('./pages/message-page/Error404page'))
// const MessagePage     = lazy(() => import('./pages/message-page/MessagePage'))
// const ResponseDetails = lazy(() => import('./pages/response-page/ResponseDetails'))
// const ResponsePage    = lazy(() => import('./pages/response-page/ResponsePage'))

function App() {
  const cookies = new Cookies()

  return (
    <>
    <NotificationContainer />

    <Router>
      <Navbar>
          <Routes>
            <Route path='/' element={cookies.get('ucsc') ? <Navigate to='/dashboard' /> : <LoginPage />} />
            
            {/* Protected Routes */}
            <Route path='/' element={<ProtectedRoutes />}>
              <Route path='/dashboard' element={<HomePage />} />
              <Route path='/new/:url' element={<FormCreate />} />
              <Route path='/edit/:url' element={<FormEdit />} />
              <Route path='/admin/responses' element={<ResponsePage />} />
              <Route path='/admin/responses/list/:id' element={<ResponseDetails />} />
              <Route path='/chart' element={<ChartPage />} />
              <Route path='/report/create' element={<ReportCreatePage />} />
              <Route path='/report/edit' element={<ReportEditPage />} />
            </Route>

            <Route path='/view/:url' element={<FormView />} />
            <Route path='/thank-you' element={<MessagePage />} />
            <Route path='*' element={<Error404page />} />
          </Routes>
      </Navbar>
    </Router>
    </>
  );
}

export default App;
