import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Cookies from "universal-cookie";

export default function ProtectedRoutes() {
    const cookies = new Cookies();
	const token = cookies.get("ucsc")

    if (token) {
        return <Outlet />;
    } else {
        return <Navigate to="/" />;
    }
}
