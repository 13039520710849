import { toast } from "react-toastify";

/**
 * 
 * @param {String} msg Notification Message
 * @param {String} type OPTIONAL: Notification Type Such as 'SUCCESS', 'FAIL'
 */
export default function raiseNotification(msg, type = 'FAIL'){
	toast.dismiss()
	
	if(type === 'SUCCESS'){
		return toast.success(msg, {
			position: "top-center",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "light",
		});
	} else {
		return toast.error(msg, {
			position: "top-center",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "light",
		});
	}
}