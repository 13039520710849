import React from "react";
import "./messagepage.css";

export default function Error404page() {
    return (
        <div className="message__wrapper">
            <div className="message__container">
                <h3>Not Found !</h3>
            </div>
        </div>
    );
}
