import React, { useRef } from "react";
import Cookies from "universal-cookie";
import { loginRequest } from "../../api/apiRequest";
import raiseNotification from "../../utility/notification";
import "./login.css";

export default function LoginPage() {
    const cookies = new Cookies();

    const username = useRef();
    const password = useRef();

    const handleLogin = async (e) => {
		e.preventDefault()
		const errors = ["", null, undefined]
		
		if(errors.includes(username.current.value)){
			return raiseNotification('Username required')
		}
		
		if(errors.includes(password.current.value)){
			return raiseNotification('Password required')
		}

        const payload = {
            username: username.current.value,
            password: password.current.value,
        };

        const res = await loginRequest(payload);

        if (res.flag === "SUCCESS") {
            cookies.set("ucsc", res?.token, { path: "/" });
            window.location.href = "/dashboard";
        } else {
            raiseNotification(res?.message);
        }
    };

    return (
        <>
            <form className="login__container" onSubmit={handleLogin}>
                <h3 className="login__title">Welcome</h3>
                <input
                    type="text"
                    placeholder="Username"
                    ref={username}
                    className="login__field"
                />
                <input
                    type="password"
                    placeholder="Password"
                    ref={password}
                    className="login__field"
                />
                <button className="login__btn" type="submit">Login</button>
            </form>
        </>
    );
}
