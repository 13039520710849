export const apiEndpoints = {
	check_url						: "/api/form/check-topic-exists",
	create_form						: "/api/form/create",
	update_form						: "/api/form/update",
	get_form						: "/api/form/fetch-by-url/",

	save_answers					: "/api/answer/save",
	save_multiple_answers			: "/api/answer/multiple-save",
	topic_list						: "/api/form/topic/list",
	topic_questions_by_id			: '/api/form/fetch-question-by-id/',
	topic_details_by_id				: "/api/answer/fetch-details-by-id/",
	topic_details_by_id_page		: "/api/answer/fetch-details-by-id-page/",

	save_report						: "/api/report/save",
	get_report						: "/api/report/fetch/",
	update_report					: "/api/report/update/",
	check_report_exist				: "/api/report/check-exist/",
	delete_report					: "/api/report/delete/",

	user_login 						: "/api/user/login"
}
// export const apiEndpoints = {
// 	check_url: "/form/check-topic-exists",
// 	create_form: "/form/create",
// 	update_form: "/form/update",
// 	get_form: "/form/fetch-by-url/",
// 	save_answers: "/answer/save",
// 	save_multiple_answers: "/answer/multiple-save",
// 	topic_list: "/form/topic/list",
// 	topic_questions_by_id: '/form/fetch-question-by-id/',
// 	topic_details_by_id: "/answer/fetch-details-by-id/",
// 	topic_details_by_id_page: "/answer/fetch-details-by-id-page/",
// 	save_report: "/report/save",
// 	get_report: "/report/fetch/",
// 	update_report: "/report/update/",
// 	check_report_exist: "/report/check-exist/",
// 	delete_report: "/report/delete/"
// }