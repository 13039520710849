import React from 'react'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import "./navbar.css"

export default function Navbar({ children }) {
  const navigate = useNavigate()
  const cookies = new Cookies()

  const handleLogout = () => {
    cookies.remove("ucsc")
    window.location.href = '/'
  }

  return (
    <>
      <div className='navbar'>
        <div className='navbar__logo-wrapper'>
          <img className='navbar__logo' src={window.location.origin + '/logo.png'} alt="Survey System" />
          <span className="navbar__title">Survey Software</span>
        </div>

        {
          cookies.get('ucsc')
          &&
          <div className='navbar__btn-wrapper'>
            <button className='navbar__btn' onClick={() => navigate('/dashboard')}>Create Survey</button>
            <button className='navbar__btn' onClick={() => navigate('/admin/responses')}>Survey Responses</button>
            <button className='navbar__btn' onClick={handleLogout}>Logout</button>
          </div>
        }
      </div>

      { children }
    </>
  )
}
