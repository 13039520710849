import axios from "axios"
import Cookies from "universal-cookie"
import { apiEndpoints } from "./endpoints"

const cookies = new Cookies()

const client = axios.create({ 
	baseURL: "http://survey.ryansmedia.com", 
	headers: {
		Authorization: cookies.get('ucsc')
	} 
})

export const loginRequest = async (payload) => {
	try {
		const res = await client.post(apiEndpoints.user_login, payload)
		return res.data
	} catch (error) {
		console.log(error.message);
		return null
	}
}

export const checkUrlExists = async (payload) => {
	try {
		const res = await client.post(apiEndpoints.check_url, payload)
		return res.data
	} catch (error) {
		console.log(error.message);
		return null
	}
}

export const createForm = async (payload) => {
	try {
		const res = await client.post(apiEndpoints.create_form, payload)
		return res.data
	} catch (error) {
		console.log(error.message);
		return null
	}
}

export const updateForm = async (payload) => {
	try {
		const res = await client.patch(apiEndpoints.update_form, payload)
		return res.data
	} catch (error) {
		console.log(error.message);
		return null
	}
}

export const getForm = async (payload) => {
	try {
		const res = await client.get(apiEndpoints.get_form + `${payload}`)
		return res.data
	} catch (error) {
		console.log(error.message);
		return null
	}
}

export const saveAnswers = async (payload) => {
	try {
		const res = await client.post(apiEndpoints.save_answers, payload)
		return res.data
	} catch (error) {
		console.log(error.message);
		return null
	}
}

export const saveMultipleAnswers = async (payload) => {
	try {
		const res = await client.post(apiEndpoints.save_multiple_answers, payload)
		return res.data
	} catch (error) {
		console.log(error.message);
		return null
	}
}

export const getTopicList = async () => {
	try {
		const res = await client.get(apiEndpoints.topic_list)
		return res.data
	} catch (error) {
		console.log(error.message);
		return null
	}
}

export const getTopicDetailsById = async (payload) => {
	try {
		const res = await client.get(apiEndpoints.topic_details_by_id + payload)
		return res.data
	} catch (error) {
		console.log(error.message);
		return null
	}
}

export const getTopicDetailsByIdPage = async (payload) => {
	try {
		const res = await client.get(apiEndpoints.topic_details_by_id_page + payload)
		return res.data
	} catch (error) {
		console.log(error.message);
		return null
	}
}

export const getTopicQuestionsById = async (payload) => {
	try {
		const res = await client.get(apiEndpoints.topic_questions_by_id + payload)
		return res.data
	} catch (error) {
		console.log(error.message);
		return null
	}
}

export const saveReport = async (payload) => {
	try {
		const res = await client.post(apiEndpoints.save_report, payload)
		return res.data
	} catch (error) {
		console.log(error.message);
		return null
	}
}

export const getReport = async (payload) => {
	try {
		const res = await client.get(apiEndpoints.get_report + payload)
		return res.data
	} catch (error) {
		console.log(error.message);
		return null
	}
}

export const updateReport = async (payload) => {
	try {
		const res = await client.patch(apiEndpoints.update_report, payload)
		return res.data
	} catch (error) {
		console.log(error.message);
		return null
	}
}

export const checkIfReportExist = async (payload) => {
	try {
		const res = await client.get(apiEndpoints.check_report_exist + payload)
		return res.data
	} catch (error) {
		console.log(error.message);
		return null
	}
}

export const deleteReport = async (payload) => {
	try {
		const res = await client.delete(apiEndpoints.delete_report + payload)
		return res.data
	} catch (error) {
		console.log(error.message);
		return null
	}
}